<template>
  <svg
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
  >
    <path
      d="M27 8C26.4477 8 26 8.44772 26 9V13C26 13.5523 26.4477 14 27 14H29C29.5523 14 30 13.5523 30 13V9C30 8.44772 29.5523 8 29 8H27Z"
    />
    <path
      d="M14 32C14 30.8954 14.8954 30 16 30H32C33.1046 30 34 30.8954 34 32C34 33.1046 33.1046 34 32 34H16C14.8954 34 14 33.1046 14 32Z"
    />
    <path
      d="M16 36C14.8954 36 14 36.8954 14 38C14 39.1046 14.8954 40 16 40H32C33.1046 40 34 39.1046 34 38C34 36.8954 33.1046 36 32 36H16Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M39.1716 3.17157L46 10V42C46 44.2091 44.2091 46 42 46H6C3.79086 46 2 44.2091 2 42V6C2 3.79086 3.79086 2 6 2H36.3431C37.404 2 38.4214 2.42143 39.1716 3.17157ZM6 6L12 6V18C12 19.1046 12.8954 20 14 20H34C35.1046 20 36 19.1046 36 18V6H36.3431L42 11.6569V42H40V28C40 25.7909 38.2091 24 36 24H12C9.79086 24 8 25.7909 8 28V42H6L6 6ZM16 16V6H32V16H16ZM12 42H36V28H12V42Z"
    />
  </svg>
</template>
